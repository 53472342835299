<template>
  <b-col class="text-center px-5">
    <div :class="`fighter__img-box border ${is_first===true? 'border-primary': 'border-danger' }`"
         :style="`background-image: url(${photo !== null && photo !== undefined ? storageUrl + '/' + photo : require('@/assets/images/no-photo.png')}); border-width: 14px !important`">
      <b-img
          style="opacity: 0"
          rounded="circle"
          class="w-100"
          thumbnail
          fluid
          :src="require('@/assets/images/no-photo.png')"
          :alt="name">
      </b-img>
    </div>
    <h2 class="text-white mt-3">{{ name }}</h2>
    <h4 class="text-white mt-3 text-warning">{{ club }}</h4>
  </b-col>
</template>

<script>
import config from '@/config'

export default {
  props: {
    club: {
      type: String,
      default: '-'

    }, photo: {
      type: String,
    },
    is_first: {
      type: Boolean,
    },
    name: {
      type: String,
      default: '-'
    }
  },
  computed: {
    storageUrl() {
      return config.storageUrl;
    }
  }
}
</script>

<style lang="scss" scoped>
.fighter__img-box {
  border-radius: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
</style>