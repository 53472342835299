<template>
    <div class="loading" v-if="show">
        <b-spinner
            style="width: 3rem; height: 3rem;"
            variant="success"
            label="Spinning"
        ></b-spinner>
    </div>
</template>

<script>
export default {
    props: {
        show: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style scoped>
    .loading {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        height: 100vh;
        z-index: 1031;
        width: 100%;
        background-color: #18191a;
    }
</style>