<template>
    <div class="prev-fight">
        <b-row class="text-white border-bottom">
            <b-col class="border-right p-3">
                <b-row>
                    <b-col sm="10" class="align-middle h-100 text-right">
                        <h4>{{ data.fighter_1_name }} ({{ data.event_info_1.name }})</h4>
                        <h5>Рекорд: {{ data.record_1 }} / Возраст: {{ data.years_1 }} / Вес: {{ data.event_info_1.current_weight }}</h5>
                    </b-col>

                    <b-col sm="2">
                        <div 
                            class="fighter__img-box float-right rounded-circle border border-primary" style="border-width: 3px !important;"
                            :style="`background-image: url(${data.photo_1 ? storageUrl + '/' + data.photo_1 : require('@/assets/images/no-photo.png')});`">
                        </div>
                    </b-col>
                </b-row>
            </b-col>

            <b-col class="d-flex align-items-center justify-content-center flex-direction-column">
                <h5 class="mb-0">Предыдущий поединок</h5>
                <p class="mb-0">Победитель: {{ data.winner }}</p>
            </b-col>

            <b-col class="border-left p-3">
                <b-row>
                    <b-col sm="2">
                        <div
                            class="fighter__img-box float-right rounded-circle border border-danger" style="border-width: 3px !important;"
                            :style="`background-image: url(${data.photo_2 ? storageUrl + '/' + data.photo_2 : require('@/assets/images/no-photo.png')})`">
                        </div>
                    </b-col>

                    <b-col sm="10" class="align-middle h-100">
                        <h4>{{ data.fighter_2_name }} ({{ data.event_info_2.name }})</h4>
                        <h5>Рекорд: {{ data.record_2 }} / Возраст: {{ data.years_2 }} / Вес: {{ data.event_info_2.current_weight }}</h5>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import config from '@/config'

export default {
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    computed: {
        storageUrl() {
            return config.storageUrl;
        }
    }
}
</script>

<style lang="scss" scoped>
    .prev-fight {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        background-color: #242526;
        z-index: 1050;
    }

    .flex-direction-column {
        flex-direction: column !important;
    }

    .fighter__img-box {
        height: 70px;
        width: 70px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
</style>