<template>
    <div class="next-fight">
        <b-row class="text-white border-top">
            <b-col class="border-right p-3">
                <b-row>
                    <b-col sm="10" class="align-middle h-100">
                        <h4 class="float-right">{{ data.fighter_1_name }} ({{ data.event_info_1.name }})</h4>
                        <h5 class="float-right">Рекорд: {{ data.record_1 }} / Возраст: {{ data.years_1 }} / Вес: {{ data.event_info_1.current_weight }}</h5>
                    </b-col>

                    <b-col sm="2">
                        <div 
                            class="fighter__img-box float-right rounded-circle border border-dark"
                            :style="`background-image: url(${data.photo_1 ? storageUrl + '/' + data.photo_1 : require('@/assets/images/no-photo.png')})`">
                        </div>
                    </b-col>
                </b-row>
            </b-col>

            <b-col class="d-flex align-items-center justify-content-center">
                <h5>Следующий поединок</h5>
            </b-col>

            <b-col class="border-left p-3">
                <b-row>
                    <b-col sm="2">
                        <div 
                            class="fighter__img-box float-left rounded-circle border border-dark"
                            :style="`background-image: url(${data.photo_2 ? storageUrl + '/' + data.photo_2 : require('@/assets/images/no-photo.png')})`">
                        </div>
                    </b-col>

                    <b-col sm="10" class="align-middle h-100">
                        <h4 class="float-left">{{ data.fighter_2_name }} ({{ data.event_info_2.name }})</h4>
                        <h5 class="float-left">Рекорд: {{ data.record_2 }} / Возраст: {{ data.years_2 }} / Вес: {{ data.event_info_2.current_weight }}</h5>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import config from '@/config'

export default {
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    computed: {
        storageUrl() {
            return config.storageUrl;
        }
    }
}
</script>

<style lang="scss" scoped>
    .next-fight {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #242526;
        z-index: 1050;
    }
    
    .fighter__img-box {
        height: 70px;
        width: 70px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
</style>