import axios from 'axios'
import store from './store'
import config from './config'

const instance = axios.create({
    baseURL: config.apiUrl
});

// Response interceptor
instance.interceptors.response.use(function(response) {
    return response;
}, function(error) {
    console.error('error', error);

    return Promise.reject(error);
});

export default instance