<template>
  <div>
    <the-loading :show="loading"></the-loading>
    <div class="home-page" v-if="!loading">
      <prev-fight
          :data="prevFight"
          v-if="prevFight !== null">
      </prev-fight>

      <div class="fight-details-container">
        <b-row>
          <fighter
              :is_first="true"
              :club="firstFighter.club"
              :name="firstFighter.name"
              :photo="firstFighter.photo">
          </fighter>

          <b-col class="bg-white rounded pb-5">
            <div class="row">
              <div class="col-12 text-center">
                <img src="@/assets/images/logo.png" class="w-25 img-responsive mt-5">
              </div>

              <the-timer
                  :time="timerData.time"
                  :status="timerData.status"
                  :stage="timerData.stage"
                  :order="currentFight.order"
              >
              </the-timer>

              <b-col sm="12">
                <b-row class="fight-details">
                  <b-col class="text-right p-1 pr-3">
                    <strong>{{ firstFighter.age }}</strong>
                  </b-col>
                  <div class="border border-white bg-dark text-white text-center px-3 rounded">Возраст</div>
                  <b-col class="text-left p-1 pl-3">
                    <strong>{{ secondFighter.age }}</strong>
                  </b-col>
                </b-row>
              </b-col>

              <b-col sm="12">
                <b-row class="fight-details">
                  <b-col class="text-right p-1 pr-3">
                    <strong>{{ firstFighter.weight }}</strong>
                  </b-col>
                  <div class="border border-white bg-dark text-white text-center px-3 rounded">Вес</div>
                  <b-col class="text-left p-1 pl-3">
                    <strong>{{ secondFighter.weight }}</strong>
                  </b-col>
                </b-row>
              </b-col>

              <b-col sm="12">
                <b-row class="fight-details">
                  <b-col class="text-right p-1 pr-3">
                    <strong>{{ firstFighter.record }}</strong>
                  </b-col>
                  <div class="border border-white bg-dark text-white text-center px-3 rounded">Рекорд</div>
                  <b-col class="text-left p-1 pl-3">
                    <strong>{{ secondFighter.record }}</strong>
                  </b-col>
                </b-row>
              </b-col>

<!--              <b-col sm="12">
                <b-row class="fight-details">
                  <b-col class="text-left p-1 pl-3">
                    <b-img
                        style="opacity: 1; width: 60px; height: 60px; object-fit: contain"
                        rounded="circle"
                        class="w-100"
                        thumbnail
                        fluid
                        :src="storageUrl + '/' + firstFighter.club_logo"
                        >
                    </b-img>
                  </b-col>
                  <div class="border border-white bg-dark text-white text-center px-3 rounded">Клуб</div>
                  <b-col class="text-left p-1 pl-3">
                    <b-img
                        style="opacity: 1; width: 60px; height: 60px; object-fit: contain"
                        rounded="circle"
                        class="w-100"
                        thumbnail
                        fluid
                        :src="storageUrl + '/' + secondFighter.club_logo"
                    >
                    </b-img>
                  </b-col>
                </b-row>
              </b-col>-->
            </div>
          </b-col>

          <fighter
              :club="secondFighter.club"
              :name="secondFighter.name"
              :photo="secondFighter.photo">
          </fighter>
        </b-row>
      </div>

      <next-fight
          :data="nextFight"
          v-if="nextFight !== null">
      </next-fight>
    </div>
  </div>
</template>

<script>
import axios from '@/axios'
import TheLoading from '../components/TheLoading'
import Fighter from '../components/Fighter/Fighter'
import TheTimer from '../components/TheTimer/TheTimer'
import NextFight from '../components/Fight/NextFight'
import PrevFight from '../components/Fight/PrevFight'
import config from '@/config'

export default {
  name: 'Home',
  computed: {
    storageUrl() {
      return config.storageUrl;
    }
  },
  components: {
    TheLoading,
    Fighter,
    TheTimer,
    NextFight,
    PrevFight
  },
  data() {
    return {
      currentFight: {
        order: 0
      },
      prevFight: {},
      nextFight: {},
      loading: true,
      timerData: {
        time: null,
        stage: 0,
        status: null,
      },
      firstFighter: {},
      secondFighter: {},
    }
  },
  sockets: {
    timer(data) {
      if (data.state !== 'ended') {
        let {minutes, seconds, round, state} = data;
        let formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

        this.timerData = {
          time: `0${minutes}:${formattedSeconds}`,
          stage: round,
          status: state,
        };
      }
    },

    paused(data) {
      this.timerData.status = data.state;
    },

    paused_break(data) {
      if (data.event === 'round_switched') {
        console.log('[Home.vue] roundSwitched', data)
      } else {
        console.log('[Home.vue] pausedBreak', data)
      }
    },

    fullended(data) {
      console.log('[Home.vue] fullEnded', data)
    },

    ended() {
      this.timerData = {}
    },

    monitorReload(data) {
      const {token} = data;
      console.log({token})

      if (token === localStorage.getItem('token')) {
        location.reload();
      }
    }
  },
  mounted() {
    this.loading = true;
    axios(`correctTime/${localStorage.getItem('token')}`)
        .then(result => {
          let {data} = result;

          this.currentFight = data.current;
          this.prevFight = data.prev;
          this.nextFight = data.next;
          this.loading = false;

          if (this.currentFight) {
            // Join the fight room
            this.$socket.emit('join', {
              room: this.currentFight.fight_id
            });

            // Set timer data
            this.timerData = {
              time: this.currentFight.fight_time,
              status: this.currentFight.fight_status,
              stage: this.currentFight.fight_stage
            }
            // Set fighter info
            this.firstFighter = {
              name: this.currentFight.fighter_1_name,
              record: this.currentFight.record_1,
              age: this.currentFight.years_1,
              photo: this.currentFight.photo_1,
              weight: this.currentFight.event_info_1.current_weight,
              club: this.currentFight.event_info_1.club_name,
              club_logo: this.currentFight.event_info_1.club_logo,
            }

            this.secondFighter = {
              name: this.currentFight.fighter_2_name,
              record: this.currentFight.record_2,
              age: this.currentFight.years_2,
              photo: this.currentFight.photo_2,
              weight: this.currentFight.event_info_2.current_weight,
              club: this.currentFight.event_info_2.club_name,
              club_logo: this.currentFight.event_info_2.club_logo,
            }
          }

        });
  },
  beforeDestroy() {
    // Leave the fight (sockets)
    this.$socket.emit('leave', {
      room: this.currentFight.fight_id
    });
  },
}
</script>

<style lang="scss" scoped>
.home-page {
  min-height: 100vh;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fight-details {
  font-size: 30px;
  margin-top: 10px;
}
</style>