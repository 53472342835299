<template>
    <b-col sm="12" class="mt-3 mb-3">
        <div class="the-timer bg-dark">
            <span class="the-timer__time">{{ time }}</span>
            <p class="the-timer__status">{{ status | formatFightStatus }}</p>
            <p class="the-timer__round">Раунд {{ stage }}</p>
            <p class="the-timer__order">Текущая очередь: {{ order }}</p>
        </div>
    </b-col>
</template>

<script>
export default {
    props: {
        time: {
            type: String,
            default: '00:00'
        },
        status: {
            type: String,
            default: '--'
        },
        stage: {
            type: [Number, String],
            default: '-'
        },
        order: {
            type: [Number, String],
            default: '-'
        }
    },
    filters: {
        formatFightStatus(value) {
            switch (value) {
                case 'pending':
                    return 'В ожидании'
                    break;
                case 'inprocess':
                    return 'Бой идёт'
                    break;
                case 'paused':
                    return 'Пауза'
                    break;
                case 'paused_break':
                    return 'Перерыв между раундами'
                    break;
                case 'rounds-ended':
                    return 'Раунды завершены'
                    break;
                case 'ended':
                    return 'Бой окончен'
                    break;
                case 'selected':
                    return 'Бой выбран'
                    break;

                default:
                    return '--'
                    break;
            }
        }
    },
}
</script>

<style lang="scss" scoped>
    .the-timer {
        text-align: center;
        padding: 5px 5px 15px 5px;
        line-height: 1.2;
        color: #fff;
        margin: 0 20%;
        border-radius: 5px;

        &__time {
            font-family: ds-digi;
            font-size: 100px;
            color: #fff;
            line-height: 1;
        }

        &__status {
            font-size: 30px;
            color: #4caf50;
            margin-bottom: 0;
        }

        &__round, &__order {
            font-size: 20px;
            margin-bottom: 0;
        }
    }
</style>