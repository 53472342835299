import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'

Vue.use(VueRouter)

const router = new VueRouter({
    routes
})



router.beforeEach((to, from, next) => {
    const token = localStorage.getItem('token');
    
    if(to.meta.requiresAuth) {
        if(token !== undefined && token !== null) {
            next();
        } else {
            next('/login')
        }
    } else {
        next();
    }
})

export default router
